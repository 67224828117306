<template>
  <div class="container">
    <content-title :nav="nav"></content-title>

    <div class="contentList">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户反馈" name="feedback"> </el-tab-pane>
        <el-tab-pane label="问卷调查" name="q2"></el-tab-pane>
        <el-tab-pane label="社区问卷" name="q3"></el-tab-pane>
      </el-tabs>

      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="提交用户">
          <user-select :userid.sync="user_id"> </user-select>
        </el-form-item>
         <el-form-item label="社区" v-if="activeName==='q3'">
          <mechanism-select :mecid.sync="mecid" :condition="{is_community:true}"></mechanism-select>
        </el-form-item>
       
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>

      <auto-table
        :DataList="DataList"
        :option="Option"
        :total="Total"
        @changePage="getFeekList"
      >
        <template #user_id="{ row }">
          <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }">
            {{ row.user_id }}-查看</link-to
          >
        </template>
        <template #content="{ row }">
          <div v-if="activeName == 'feedback'">{{ row.content }}</div>
          <div v-else>请查看详情</div>
        </template>
        <template #pic="{ row }">
          <img v-if="row.pic" :src="row.pic.split(',')[0]" class="img" />
        </template>
        <template #handle="{ row }">
          <el-button @click="toDetails(row)" size="small" type="success"
            >查看详情</el-button
          >
        </template>
      </auto-table>
    </div>
    <el-dialog :visible.sync="feedbackShow" title="反馈信息">
      <div class="inwrap">
        {{ cur.content }}
      </div>
      <div v-if="cur.pic">
        <el-image
          v-for="(p, i) in cur.pic.split(',')"
          :src="p"
          :key="i"
          :previewSrcList="cur.pic.split(',')"
          class="img"
        ></el-image>
      </div>
      <span slot="footer">
        <el-button @click="feedbackShow = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="examVisible" title="反馈信息">
      <div class="inwrap" v-if="exam.length > 0">
        <div>姓名：{{ exam[0].name }}</div>
        <div>生日：{{ exam[0].birth }}</div>
        <div>社区：{{ exam[0].area }}</div>

        <div v-for="(v, index) in exam.slice(1)" :key="index" class="mb">
          <div>{{ v.id + ". " }}{{ v.problem }}</div>
          <div>{{ v.answer }} &nbsp;&nbsp; {{ v.text }}</div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="examVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="q3Show" title="反馈信息">
      <div class="inwrap">
        <div class="mb">
          <div>身份：{{ curInfo.role }}</div>
          <div v-if="curInfo.kid" >
            宝贝姓名：{{ curInfo.kid.name }}
          </div>
          <div v-if="curInfo.kid">
            宝贝生日：{{ curInfo.kid.birth }}
          </div>
        </div>

        <div v-for="(v, index) in curInfo.q" :key="index" class="mb">
          <div>{{ v.problem }}</div>
          <div>{{ v.answer }}</div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="q3Show = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import newMec from "../../components/newMec.vue";
import MechanismSelect from '../../components/select/mechanismSelect.vue';
export default {
  components: { newMec, MechanismSelect },
  data() {
    return {
      nav: {
        firstNav: "运营管理中心",
        secondNav: "用户反馈列表",
      },
      Option: [
        { name: "用户", value: "user_id", type: "custom" },
        { name: "内容", value: "content", type: "custom" },
        { name: "图片", value: "pic", type: "custom" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      DataList: [],
      Total: 0,
      type: 1,
      activeName: "feedback",
      feedbackShow: false,
      cur: {},
      examVisible: false,
      exam: [],
      q3Show: false,
      curInfo: {},
      user_id: "",
      mecid:''
    };
  },
  created() {
    this.getFeekList(1);
  },
  methods: {
    search() {
      this.getFeekList(1)
    },
    // 反馈列表
    getFeekList(v) {
      let page = v || 1;
      let url = "/message/systemFeedBack/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 1,
            currentPage: page,
            pageSize: 10,
            type: this.type || null,
            user_id: this.user_id || null,
            mechanism_id:this.mecid || null
          },
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
        });
    },
    handleClick(tab) {
      switch (tab.name) {
        case "feedback":
          this.type = 1;
          break;
        case "q2":
          this.type = 2;
          break;
        case "q3":
          this.type = 3;
          break;
      }
      this.getFeekList(1);
    },
    toDetails(row) {
      switch (this.activeName) {
        case "feedback":
          this.cur = row;
          this.feedbackShow = true;
          break;
        case "q2":
          this.exam = JSON.parse(row.content);
          this.examVisible = true;
          break;
        case "q3":
          this.type = 3;
          this.q3Show = true;
          this.curInfo = JSON.parse(row.content);

          break;
      }
    },
  },
};
</script>
<style>
.PERInformation-from > *:not(:last-child) {
  margin-right: 1rem;
}
.img {
  width: 100px;
  height: 100px;
}
.ell {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.inwrap {
  padding: 20px;
}
.mb {
  margin-bottom: 10px;
}
</style>
